.h3_alternate {
  color: var(--text-color);
}

.caret {
  color: var(--text-color);
  animation: blink-caret 1s step-end infinite;
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    color: transparent;
  }
  50% {
    color: var(--text-color);
  }
}
