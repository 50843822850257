/* 
************ COLORS ***********
*/
:root {
  --terminal-color: green;
  --text-color: #fff;
}

/* 
************ FONTS ***********
*/
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&family=Source+Code+Pro&display=swap');
:root {
  --default-font-family: 'Source Code Pro', monospace;
  --secondary-font-family: 'Roboto Mono', monospace;
  --default-font: 200 18px/1.25em var(--default-font-family);
}

body {
  margin: 0;
  font: var(--default-font);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

h1,
h2,
h3 {
  color: var(--terminal-color);
  font-family: var(--secondary-font-family);
}

ul {
  list-style: none;
}

ul li::before {
  content: '\2022';
  color: var(--terminal-color);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
