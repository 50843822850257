@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&family=Source+Code+Pro&display=swap);
/* 
************ COLORS ***********
*/
:root {
  --terminal-color: green;
  --text-color: #fff;
}

/* 
************ FONTS ***********
*/
:root {
  --default-font-family: 'Source Code Pro', monospace;
  --secondary-font-family: 'Roboto Mono', monospace;
  --default-font: 200 18px/1.25em var(--default-font-family);
}

body {
  margin: 0;
  font: 200 18px/1.25em 'Source Code Pro', monospace;
  font: var(--default-font);
  color: #fff;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

h1,
h2,
h3 {
  color: green;
  color: var(--terminal-color);
  font-family: 'Roboto Mono', monospace;
  font-family: var(--secondary-font-family);
}

ul {
  list-style: none;
}

ul li::before {
  content: '\2022';
  color: green;
  color: var(--terminal-color);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App_link__3NCEJ {
  display: block;
  padding: 1em 2em;
  color: var(--text-color);
}

.App_link__3NCEJ:hover {
  color: var(--terminal-color);
}

.contact_link__2MTjV {
}

.contact_divider__DOHsY {
  background-color: var(--text-color);
}

.navLinks_link__2GpdB {
}

.hamburgerMenu_hamburger__2KE7r {
  color: var(--text-color);
}

.header_header__3UWhk {
  margin-top: 1em;
}

.introduction_h3_alternate__2f42S {
  color: var(--text-color);
}

.introduction_caret__25xpf {
  color: var(--text-color);
  -webkit-animation: introduction_blink-caret__1LLp9 1s step-end infinite;
          animation: introduction_blink-caret__1LLp9 1s step-end infinite;
}

/* The typewriter cursor effect */
@-webkit-keyframes introduction_blink-caret__1LLp9 {
  from,
  to {
    color: transparent;
  }
  50% {
    color: var(--text-color);
  }
}
@keyframes introduction_blink-caret__1LLp9 {
  from,
  to {
    color: transparent;
  }
  50% {
    color: var(--text-color);
  }
}

.link {
  display: block;
  padding: 1em 2em;
  color: var(--text-color);
}

.link:hover {
  color: var(--terminal-color);
}

